<template>
  <div>
    <div
      class="modal fade"
      id="modal-form-km"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      style="background-color: #00000082"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white" v-if="turno">
              <strong>Kilometros Turno: </strong> {{ turno.id }}
            </h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModalKm"
              @click="limpiarModal()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" v-if="turno">
            <div class="row">
              <div class="col-md-12">
                <div class="position-relative p-3 m-2 bg-light">
                  <div class="ribbon-wrapper ribbon-lg">
                    <div class="ribbon alert-default-info">Kilometros</div>
                  </div>

                  <div class="row">
                    <div class="col-md-4">
                      <div
                        class="small-box alert-default-dark mb-1"
                        style="min-height: 130px"
                      >
                        <div class="inner">
                          <h3 style="font-size: 25px">Km. Webservice:</h3>
                          <h3 style="font-size: 25px">{{ turno.km_ws }}</h3>
                        </div>
                        <div class="icon">
                          <i class="fas fa-road"></i>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div
                        class="small-box alert-default-dark mb-1"
                        style="min-height: 130px"
                      >
                        <div class="inner">
                          <h3 style="font-size: 25px">Km. Odometros:</h3>
                          <h3 style="font-size: 25px">{{ km_odo }}</h3>
                        </div>
                        <div class="icon">
                          <i class="fas fa-road"></i>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div
                        class="small-box alert-default-dark mb-1"
                        style="min-height: 130px"
                      >
                        <div class="inner">
                          <h3 style="font-size: 25px">Km Final:</h3>
                          <div class="row">
                            <div class="col-md-4">
                              <input
                                class="form-control form-control-sm"
                                style="font-size: 18px"
                                type="text"
                                v-model="form.km_final"
                                :disabled="
                                  !$store.getters.can(
                                    'tif.conciliaciones.kmFinal'
                                  ) || turno.km_final
                                "
                              />
                            </div>
                            <div class="col-md-2">
                              <button
                                type="button"
                                class="btn btn-sm alert-default-success"
                                :disabled="!form.km_final || turno.km_final"
                                @click="save()"
                              >
                                <i class="fas fa-check"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div class="icon">
                          <i class="fas fa-road"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="position-relative p-3 m-2 bg-light">
                  <div class="ribbon-wrapper ribbon-lg">
                    <div class="ribbon alert-default-dark">Odometros</div>
                  </div>
                  <div class="row">
                    <div class="col-md-11">
                      <div class="row pl-5" v-if="turno.tif_odometro">
                        <div
                          class="
                            col-sm-6
                            d-flex
                            align-items-stretch
                            flex-column
                          "
                          v-if="turno.tif_odometro.inicial"
                        >
                          <div class="col-md-12">
                            <div
                              class="small-box alert-default-info mb-1"
                              style="min-height: 270px"
                            >
                              <div class="inner">
                                <h3 style="font-size: 33px">
                                  Odómetro Inicial:
                                </h3>
                                <h3 style="font-size: 23px">
                                  {{ turno.tif_odometro.inicial }}
                                </h3>
                              </div>
                              <div class="icon">
                                <i class="fas fa-tachometer-alt"></i>
                              </div>
                              <div
                                class="col-md-9 pl-4 text-center"
                                v-if="turno.tif_odometro.link_ini"
                              >
                                <img
                                  @click="getImage(turno.tif_odometro.link_ini)"
                                  :src="uri_docs + turno.tif_odometro.link_ini"
                                  alt="Odómetro Inicial"
                                  class="img-bordered img-rounded img-fluid"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="
                            col-md-6
                            d-flex
                            align-items-stretch
                            flex-column
                          "
                          v-if="turno.tif_odometro.final"
                        >
                          <div class="col-md-12">
                            <div
                              class="small-box alert-default-info mb-1"
                              style="min-height: 270px"
                            >
                              <div class="inner">
                                <h3 style="font-size: 33px">Odómetro Final:</h3>
                                <h3 style="font-size: 23px">
                                  {{ turno.tif_odometro.final }}
                                </h3>
                              </div>
                              <div class="icon">
                                <i class="fas fa-tachometer-alt"></i>
                              </div>
                              <div
                                class="col-md-9 pl-4 text-center"
                                v-if="turno.tif_odometro.link_fin"
                              >
                                <img
                                  @click="getImage(turno.tif_odometro.link_fin)"
                                  :src="uri_docs + turno.tif_odometro.link_fin"
                                  alt="Odómetro Inicial"
                                  class="img-bordered img-rounded img-fluid"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        v-else
                        class="
                          col-md-11
                          pl-5
                          d-flex
                          align-items-stretch
                          flex-column
                        "
                      >
                        <div
                          class="small-box alert-default-warning"
                          style="min-height: 100px"
                        >
                          <div class="inner">
                            <h3>No hay Odometros</h3>
                          </div>
                          <div class="icon">
                            <i class="far fa-eye-slash"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /.modal-content -->
  </div>
  <!-- /.modal-dialog -->
</template>
<script>
import axios from "axios";
export default {
  name: "TifKilometrosTurno",
  components: {},
  data() {
    return {
      turno: null,
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
      km_odo: null,
      form: {
        id: null,
        km_final: null,
        tif_turno_id: null,
        user_id: null,
      },
    };
  },

  methods: {
    async llenar_modal_km(item) {
      this.turno = await item;
      if (this.turno.tif_odometro) {
        this.km_odo =
          parseFloat(this.turno.tif_odometro.final) -
          parseFloat(this.turno.tif_odometro.inicial);
      }

      this.form.user_id = await this.$store.getters.getUser.id;
      this.form.tif_turno_id = await this.turno.id;

      if (this.turno.tif_km) {
        this.form.km_final = await this.turno.tif_km.km_final;
        this.form.id = await this.turno.tif_km.id;
      }else{
        this.form.km_final = await this.turno.km_ws;
      }
    },

    getImage(url) {
      window.open(this.uri_docs + url, "_blank");
    },

    save() {
      this.$parent.cargando = true;
      axios({
        method: "PUT",
        url: "/api/tif/conciliaciones/km/save",
        data: this.form,
      })
        .then((response) => {
          this.$parent.cargando = false;
          this.$swal({
            icon: "success",
            title: "Los kilometros se guardaron exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error, vuelva a intentarlo..." + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    limpiarModal() {
      this.$parent.getIndex(this.$parent.page);
      this.form = {
        km_final: null,
        user_id: null,
      };
      this.km_odo = null;
      this.turno = null;
    },
  },

  mounted() {},
};
</script>
